import React from 'react';
import { PathService } from '../../services';
import { PressRoomOverviewContext, TemplateProps } from '../../types';
import { PressRoomOverviewView } from '../../views';

const PressRoomsTemplate: React.FunctionComponent<
  TemplateProps<PressRoomOverviewContext>
> = ({ pageContext, location }) => {
  PathService.set(location.pathname);
  return (
    <PressRoomOverviewView
      inTheNews={JSON.parse(pageContext.inTheNews)}
      recentEntries={JSON.parse(pageContext.recentEntries)}
      pressArchive={JSON.parse(pageContext.pressArchive)}
    />
  );
};

export default PressRoomsTemplate;
